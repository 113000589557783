import React from 'react';
import styled from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const SignUpSection = styled.section`
	padding: 2rem 1rem;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 5rem 0;
	}
`;

const SignUpContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	h6 {
		font-family: ${cwTheme.serif};
	}
`;

const SignUpWrapper = styled.div`
	margin-bottom: 1.66rem;
	input {
		width: 100%;
		max-width: 400px;
		box-sizing: border-box;
		height: 43px;
		font-size: 1rem;
		vertical-align: middle;
		margin-right: 1rem;
		margin-top: 1rem;
		border: 2px solid rgba(61, 61, 61, .2);
		border-radius: 2px;
		padding: 0 .5rem;
		color: rgba(61, 61, 61, 1);
		transition: .3s;
	}
	input:focus {
		border: 2px solid rgba(55, 197, 235, 1);
		outline: none;
	}
	a {
		margin-top: 1rem;
		white-space: nowrap;
		display: inline-block;
	}
`;

const SubmitButton = styled.button`
	padding: .5rem 2rem;
	vertical-align: middle;
	transition: .3s;
	display: inline-block;
	border: none;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	transition: .3s;
	font-size: 1rem;
	height: 43px;
	margin-top: 1em;
	background-color: ${(props) => props.color};
	color: ${cwTheme.white};
	&:hover,
	&:focus {
		background-color: ${(props) => props.hoverColor};
	}
`;

export default class SignUpForm extends React.Component {
	// Since `addToMailchimp` returns a promise, you
	// can handle the response in two different ways:

	// Note that you need to send an email & optionally, listFields
	// these values can be pulled from React state, form fields,
	// or wherever.  (Personally, I recommend storing in state).
	constructor(props) {
		super(props);
		this.state = { email: '' };
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		const result = await addToMailchimp(this.state.email);
		this.setState({ email: result.msg });
	};

	handleChange = (e) => {
		this.setState({ email: e.target.value });
	};

	render() {
		return (
			<SignUpSection>
				<SignUpContainer>
					<h6>{this.props.header ? this.props.header : 'Receive mindfulness right in your inbox'}</h6>
					<SignUpWrapper>
						<form onSubmit={this.handleSubmit}>
							<input placeholder="Email" value={this.state.email} onChange={this.handleChange} />
							<SubmitButton
								type="submit"
								value="Submit"
								color={cwTheme.secondaryBlue}
								hoverColor={cwTheme.secondaryHover}
							>
								Sign Up
							</SubmitButton>
						</form>
					</SignUpWrapper>
				</SignUpContainer>
			</SignUpSection>
		);
	}
}
